import React from "react"
import {Link, useStaticQuery, graphql} from "gatsby";
import styled from "styled-components";
import Container from "../styles/Container";
import Image from 'gatsby-image';

const SiteFooter = styled.footer`
  background: ${props => props.theme.graydark};
  color: #fff;
  padding-top: 80px;
  padding-bottom: 40px;
  @media(max-width: ${props => props.theme.screenXsMax};) {
  background: black;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const SiteFooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
  }
`;

const SiteFooterRight = styled.nav`
  @media(max-width: ${props => props.theme.screenXsMax}) {
    order: 1;
  }
`;

const SiteFooterLeft = styled.div`
    @media (min-width: ${props => props.theme.screenSm}) {
    width: 50%;
  }
   @media(max-width: ${props => props.theme.screenXsMax}) {
    order: 2;
  }
`;

const FooterLogo = styled(Link)`
  display: block;
  margin-bottom: 10px;
  @media (min-width: ${props => props.theme.screenSm}) {
    padding-top: 270px;
    padding-bottom: 100px;
  }
`;


const SiteFooterMenu = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    li {
    a {
      color: #fff;
      text-transform: uppercase;
      padding-bottom: 20px;
      display: inline-block;
    }
  }
`;

const SiteFooterSocial = styled.div`
  @media(max-width: ${props => props.theme.screenXsMax};) {
    padding: 15px 0;
  }
  a {
    display: inline-flex;
    margin-right: 10px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;


export default () => {

  const data = useStaticQuery(graphql`
    query {
      zzz: file(absolutePath: { regex: "/zzz.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      twitter: file(absolutePath: { regex: "/twitter.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      },

      logo: file(absolutePath: { regex: "/logo-white.png/" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)






  return (

    <SiteFooter>
      <SiteFooterContainer>
        <SiteFooterLeft>
          <SiteFooterSocial>
            <a href="https://linkedin.com/company/clean-catalog" target="_blank" rel="noopener noreferrer">
              <Image
                fixed={data.zzz.childImageSharp.fixed}
                alt="Follow Clean Catalog on LinkedIn"
              />
            </a>
            <a href="https://twitter.com/CatalogClean" target="_blank" rel="noopener noreferrer">
              <Image
                fixed={data.twitter.childImageSharp.fixed}
                alt="Follow Clean Catalog on Twitter"
              />

            </a>
          </SiteFooterSocial>
          <FooterLogo to={`/`}>
            <Image fluid={data.logo.childImageSharp.fluid} alt="Clean Catalog logo" />
          </FooterLogo>
          <p>© {new Date().getFullYear()} Clean Catalog</p>
          <p>Proudly based in Seattle, WA</p>
        </SiteFooterLeft>
        <SiteFooterRight>
          <SiteFooterMenu>
            <li>
              <Link to={`/start`}>
                Get Started
              </Link>
            </li>
            <li>
              <Link to={`/features`}>
                Features & Technology
              </Link>
            </li>
            <li>
              <Link to={`/contact`}>
                Contact
              </Link>
            </li>
          </SiteFooterMenu>
        </SiteFooterRight>
      </SiteFooterContainer>
    </SiteFooter>
  )

};



