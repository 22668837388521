import React from "react"

import Footer from "./footer";
import Header from "./header";
import { ThemeProvider } from "styled-components";
import Theme from "../styles/Theme";
import { createGlobalStyle } from "styled-components"
import SEO from "./seo";

const GlobalStyle = createGlobalStyle`
  h1 {
    font-size: ${props => props.theme.fontXl};
  }

  h2 {
    font-size: ${props => props.theme.fontLg};
  }

  h3 {
    font-size: ${props => props.theme.fontMd};
  }

  h4 {
    font-size: ${props => props.theme.fontMd2};
  }


  a {
    color: ${props => props.theme.red};
  }

`;

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Header />
        <main>
          {children}
          </main>
        <Footer />

      </ThemeProvider>
    )
  }
}

export default Layout
