import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby";
import Container from "../styles/Container";
import styled from "styled-components";
import Image from 'gatsby-image';


const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
`;

const MainNav = styled.div`
    background: ${props => props.theme.red};
  `;

const SecondNav = styled.div`

  @media(max-width: ${props => props.theme.screenMdMax}) {
    order: 2;
  }
 `;

const MenuUl = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    @media(max-width: ${props => props.theme.screenSmMax}) {
      flex-direction: column;
    }
  `;

const StyledLi = styled.li`
    padding-right: 1.5em;
    margin: 0;
    &:last-child {
      padding-right: 0;
    }
     @media(max-width: ${props => props.theme.screenMdMax}) {
      font-size: ${props => props.theme.fontSmall};

    }
  `;


const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    span {
      font-style: italic;
    }
    @media(max-width: ${props => props.theme.screenMdMax}) {
      max-width: 200px;
    }
  `;

const StyledLink = styled(Link)`
    color: #000;
    font-size: ${props => props.theme.fontSmall};
    &:visited {
      color: #000;
    }
  `;

const MainNavContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: ${props => props.theme.screenMdMax}) {
      flex-direction: column;
      align-items: flex-start;
    }
`;

const HeaderText = styled.div`
  color: #000000;
  font-size: ${props => props.theme.fontLg};
  text-transform: uppercase;
  line-height: 1.1;
  @media(max-width: ${props => props.theme.screenMdMax}) {
    font-size: ${props => props.theme.fontMd};
  }
`;

const HeaderTextLower = styled.div`
  color: #E0E0E2;
  font-size: ${props => props.theme.fontMd2};
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: .245em;
  @media(max-width: ${props => props.theme.screenMdMax}) {
    font-size: ${props => props.theme.fontSmall};
    letter-spacing: .29em;
  }
`;


export default () => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(absolutePath: { regex: "/logo.png/" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <HeaderWrapper>
      <SecondNav>
        <Container>
          <MenuUl>
            <StyledLi>
              <StyledLink to={`/start`}>
                Get Started
              </StyledLink>
            </StyledLi>
            <StyledLi>
              <StyledLink to={`/contact`}>
                Contact
              </StyledLink>
            </StyledLi>
            <StyledLi>
              <StyledLink to={`/features`}>
                Features & Technology
              </StyledLink>
            </StyledLi>
          </MenuUl>

        </Container>
      </SecondNav>
      <MainNav>
        <MainNavContainer>

          <div>
            <Link to={`/`}>
            <HeaderText >
              Employee Handbook
            </HeaderText>
            <HeaderTextLower>
              Management Software
            </HeaderTextLower>
            </Link>
          </div>
          <LogoWrapper>
            <span>Brought to you by</span>
            <a href={'https://cleancatalog.com'}>
              <Image fluid={data.logo.childImageSharp.fluid} alt="Clean Catalog logo" />
            </a>
          </LogoWrapper>

        </MainNavContainer>
      </MainNav>
    </HeaderWrapper>
  )
}

